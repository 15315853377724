import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Dropdown, Accordion, Spinner } from "react-bootstrap";
import moment from 'moment';
import Moment from 'react-moment';
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getEmployeeList, getEmployeeListByProjectId } from '../../../store/actions/EmployeeActions';
import { generatedClientReportDetails, updateClientReportStatusDetails } from '../../../store/actions/TimeCardActions';
import { sendToReviewerData, getNoteData, addNoteData } from '../../../store/actions/TimeCaptureActions';
import { getClientsData } from '../../../store/actions/ClientsActions';
import { getProjectList, getProjectByClientIdList, getProjectActivitiesListData, getAccessableTaskList, getProjectDetailsByIdData, getTaskList } from '../../../store/actions/ProjectActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getSystemUserConfigData } from '../../../store/actions/SystemDefaultActions';
import ChatBox from "../Components/ChatBox";
import InvoiceCard from "../Components/InvoiceCard";

const ClientReports = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();


    const [cardDetails, setCardDetails] = useState(null);
    const [cardList, setCardList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    //note
    const [message, setMessage] = useState('');
    const [projectId, setProjectId] = useState(null);
    const [status, setStatus] = useState(null);
    const [reportId, setReportId] = useState(null);
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(null);

    const getClientReport = () => {
        dispatch(globalLoadingAction(true));
        dispatch(generatedClientReportDetails(props.userId, props.history));
    }

    useEffect(() => {
        getClientReport();
    }, []);


    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    const onGetNoteHandler = (task_id) => {
        setProjectId(task_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(task_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }

    const onViewHalder = (data) => {
        if (data) {
            let array = JSON.parse(data.report_data);
            setReportId(data._id);
            setStatus(data.status);
            setAddModal(true);
            setCardList(array);
            setCardDetails(data)
        }
    }

    const updateCallBack = () => {
        const timer = setTimeout(() => {
        setReportId(null);
        setStatus(null);
        setLoadingStatus(null);
        setAddModal(false);
        setLoading(false);
        setCardList([]);
        getClientReport();
    }, 2000);

    // Return a cleanup function
    return () => {
        clearTimeout(timer);
    };
    }

    const onStatusHandler = (status) => {
        setLoading(true);
        setLoadingStatus(status);
        dispatch(updateClientReportStatusDetails(reportId, status, updateCallBack, history));
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return "Pending";
            case 2:
                return "Approved";
            case 3:
                return "Reject";
            default:
                return "NA";
        }
    };

    return (
        <>
            <div className="row">

                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Reporting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Reports</Link>
                        </li>
                    </ul>
                </div>

                <div className="title-body-container">
                    <div className="title-body mb-3">
                        <h3>Reports</h3>
                    </div>


                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card className='m-0'>
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.timeCardDetails.length > 0 ?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <strong>Bill Id</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Project Id</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Project Date</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Send By</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Report Date</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Status</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Actions</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.timeCardDetails.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.bill_id}</td>
                                                                    <td>{item.project_id}</td>
                                                                    <td><Moment format="D MMM YYYY" withTitle>{item.project_start_date}</Moment> - <Moment format="D MMM YYYY" withTitle>{item.project_end_date}</Moment></td>
                                                                    <td>{item.send_by_first_name} {item.send_by_last_name}</td>
                                                                    <td><Moment format="D MMM YYYY" withTitle>{item.start_date}</Moment> - <Moment format="D MMM YYYY" withTitle>{item.end_date}</Moment></td>
                                                                    <td>

                                                                        <div className="d-flex">
                                                                            {(() => {
                                                                                switch (item.status) {
                                                                                    case 4:
                                                                                        return (
                                                                                            <button className="btn btn-warning shadow btn-xs">
                                                                                                Pending
                                                                                            </button>
                                                                                        );
                                                                                    case 5:
                                                                                        return (
                                                                                            <button className="btn btn-success shadow btn-xs">
                                                                                                Approved
                                                                                            </button>
                                                                                        );
                                                                                    default:
                                                                                        return (
                                                                                            <button className="btn btn-secondary shadow btn-xs">
                                                                                                Unknown
                                                                                            </button>
                                                                                        );
                                                                                }
                                                                            })()}
                                                                        </div>

                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <button
                                                                                onClick={() => onViewHalder(item)}
                                                                                className="btn btn-info shadow btn-xs"
                                                                            >View
                                                                            </button>
                                                                            
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className="nodata-found-view">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                <h2>Data not found</h2>
                                                <p>Report data is empty or Try again search</p>
                                                <button type="button"
                                                //onClick={onCleanHandler}
                                                >
                                                    Retry
                                                </button>
                                            </div>
                                        }
                                    </>
                                    : null}
                            </Card.Body>
                        </Card>
                        <div>
                            <p><strong>Note:</strong> Use increments of 0.25 (15 minutes) when recording hours.</p>
                        </div>
                    </Col>
                </div>
            </div>

            <Modal
                className="fade bd-example-modal-sm"
                size="xl"
                show={addModal}
                onHide={() => setAddModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Invoice Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-detail-header">

                        <div className="card-detail-left">
                            <div>
                                <label className='mb-4'><strong>Bill Id:</strong> {cardDetails?.bill_id}</label>
                            </div>
                            <div>
                                {cardDetails?.project_start_date ?
                                    <label><strong>Project Date:</strong> <Moment format="D MMM YYYY" withTitle>{cardDetails.project_start_date}</Moment> - <Moment format="D MMM YYYY" withTitle>{cardDetails.project_end_date}</Moment></label> : null}
                            </div>
                        </div>
                        <div className="card-detail-left">

                            <div>
                                <label className='mb-4'><strong>Project Id:</strong> {cardDetails?.project_id}</label>
                            </div>
                            <div>

                                {cardDetails?.start_date ?
                                    <label><strong>Report Date:</strong> <Moment format="D MMM YYYY" withTitle>{cardDetails.start_date}</Moment> - <Moment format="D MMM YYYY" withTitle>{cardDetails.end_date}</Moment></label> : null}
                            </div>
                        </div>
                        <div className="card-detail-right">
                            <div>
                                <label className='mb-4'><strong>Send By:</strong> {cardDetails?.send_by_first_name} {cardDetails?.send_by_last_name}</label>
                            </div>
                            <div>
                                <label><strong>Status:</strong> {getStatusLabel(cardDetails?.status)}</label>
                            </div>
                        </div>
                    </div>
                    {cardList.length > 0 ?
                        cardList.map((item, index) => {
                            return (
                                <InvoiceCard
                                    key={index}
                                    //onNoteHandler={(id) => onGetNoteHandler(id)}
                                    note={false}
                                    details={item} />
                            )
                        })
                        :
                        <div className='text-center mt-3'><h3>No Data Found</h3></div>}
                </Modal.Body>
                <Modal.Footer>
                    {status == 4 ?
                        <>
                        <Button
                            variant="success"
                        onClick={() => onStatusHandler(5)}
                        >
                            Approve {" "}
                            {loading && loadingStatus == 5 ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                : null}
                        </Button>
                        <Button
                            variant="danger"
                        onClick={() => onStatusHandler(3)}
                        >
                            Send Back {" "}
                            {loading && loadingStatus == 3 ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                : null}
                        </Button>
                        </> : null}
                    <Button variant="secondary" onClick={() => setAddModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCard.screenVisible,
        showLoading: state.clients.showLoading,
        clientList: state.clients.clientList,
        projectList: state.project.projectList,
        activitiesList: state.project.activitiesList,
        taskList: state.project.taskList,
        countryList: state.country.countryList,
        timeCardDetails: state.timeCard.generatedDetails,
        systemAdmin: state.auth.systemAdmin,
        userId: state.auth.userId,
    };
};
export default connect(mapStateToProps)(ClientReports);