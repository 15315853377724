import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Dropdown, Accordion } from "react-bootstrap";
import moment from 'moment';


const InvoiceCard = (props) => {

    return (
        <Fragment>
            <div className='timeSheet-project-card mb-1 mt-3'>
                <div>
                    <h4 className='timeSheet-project-title'>{props?.details?.project_name}</h4>
                </div>
                <div>
                    {/* <button
                        type='button'
                        disabled={props?.details?.send_for_approval == 1 ? true : false}
                        className='assign-project-btm'
                    onClick={() => onAssignHandler(details)}
                    >Assing time Approver</button>
                    <button
                        type='button'
                        disabled={props?.details?.send_for_approval == 1 ? false : true}
                        className='note-project-btm ms-2'
                        onClick={() => props.onNoteHandler()}
                    >Note</button> */}
                </div>
            </div>
            <Table className='cart-table table-bordered'>
                <thead>
                    <tr className='cart-table-header'>
                        <th colSpan={3}>
                           
                        </th>
                        <th>
                            <strong>Sun</strong>
                        </th>
                        <th>
                            <strong>Mon</strong>
                        </th>
                        <th>
                            <strong>Tue</strong>
                        </th>
                        <th>
                            <strong>Wed</strong>
                        </th>
                        <th>
                            <strong>Thr</strong>
                        </th>
                        <th>
                            <strong>Fri</strong>
                        </th>
                        <th>
                            <strong>Sat</strong>
                        </th>
                        <th className='text-center'>
                            <strong>Total Hrs</strong>
                        </th>
                        <th className='text-center'>
                            <strong>Action</strong>
                        </th>
                    </tr>
                    <tr className='cart-table-header'>
                        <th>
                            <strong>Project</strong>
                        </th>

                        <th>
                            <strong>Activity</strong>
                        </th>
                        <th>
                            <strong>Task</strong>
                        </th>
                        {
                            props?.details?.time_table?.length > 0 &&
                            props.details.time_table.slice(0, 7).map((dates, dateIndex) => (
                                <th key={dateIndex}>
                                    <strong>{dates.date}</strong>
                                </th>
                            ))
                        }
                        <th className='text-center'>
                            <strong></strong>
                        </th>
                        <th className='text-center'>
                            {/* <strong>Action</strong> */}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props?.details?.data_table?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.project_id}</td>
                                    <td>{item.activity_id}</td>
                                    <td>{item.task_id}</td>
                                    {item.time_details?.slice(0, 7).map((times, timesIndex) => (
                                        <td key={timesIndex}>
                                            {times.working_hour}
                                        </td>
                                    ))}
                                    <td className='total-hrs'>{item.total_hour}</td>
                                    <td className='total-hrs'>
                                        {props.note == false ? null :
                                            <button
                                                onClick={() => props.onNoteHandler(item.id)}
                                                className="new-node-btn">Note</button>
                                        }
                                    </td>
                                    {/* <td className='total-hrs'>
                                                        {item.send_for_approval == 0 ?
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    className="card-sheet-btn i-false"
                                                                >
                                                                    <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                    //onClick={() => onEditHandler(item)}
                                                                    >Edit</Dropdown.Item>
                                                                    <Dropdown.Item
                                                                    //onClick={() => onDeleteHandler(item)}
                                                                    >Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            : null}
                                                    </td> */}
                                </tr>
                            );
                        })
                    }
                    <tr className='total-time'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan={2}><strong>TOTAL HOURS :</strong></td>
                        <td className='total-hrs'><strong>{props?.details?.total_hour}</strong></td>
                        <td className='total-hrs'></td>
                    </tr>
                    {props.type !== 1 ?
                        <tr className='total-time'>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colSpan={2}><strong>RATE PER HOURS :</strong></td>
                            <td className='total-hrs'><strong>${props?.details?.emp_rate ? props?.details.emp_rate : 0}</strong></td>
                            <td className='total-hrs'></td>
                        </tr>
                        : null}
                    <tr className='total-time'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan={2}><strong>TOTAL PAY :</strong></td>
                        <td className='total-hrs'><strong>${Math.floor(props?.details?.total_pay)}</strong></td>
                        <td className='total-hrs'></td>
                    </tr>
                </tbody>
            </Table>
        </Fragment>
    )
}

export default InvoiceCard;