import React, { useContext, Fragment, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import TimeCardView from "./TimeCardView";

const BilableTab = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();


    return (
        <Fragment>
            {props.data?.length > 0 ?
                <div className='report-header'>
                    <button
                        type='button'
                        onClick={() => props.reportHandler()}
                    >Approval</button>
                </div>
                : null}
            {props.data?.length > 0 ?
                props.data.map((item, index) => {
                    return (
                        <TimeCardView
                            key={index}
                            onNoteHandler={(id) => props.noteHandler(id)}
                            details={item} />
                    )
                })
                :
                <div className='text-center mt-3'><h3>No Data Found</h3></div>}

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCapture.screenVisible,
    };
};
export default connect(mapStateToProps)(BilableTab);